import React, { useEffect } from 'react'
import { Link, navigate } from 'gatsby'
// Components
import {
  Basic as LoadMoreButton,
  Loading,
  OrderCard,
  PageHeader,
  Primary as OrderButton,
  Seo,
} from '../components'
// Context
import { useAuthContext } from '../context/AuthContext'
import { usePrismic } from '../context/PrismicContext'
// Styles
import { Wrapper, Container } from '../styles/common'
// Hooks
import useOrderHistory from '../hooks/useOrderHistory'

const OrderHistoryPage = () => {
  const { isAuthenticated, isAuthLoading } = useAuthContext()

  const {
    ordersData,
    ordersPageInfo,
    isLoadingOrders,
    isLoadingMoreOrders,
    handleGetPageDataAndOrders,
    handleGetNextPageOrders,
  } = useOrderHistory()

  const {
    prismicData: {
      prismicOrderHistoryPage: {
        end_of_orders,
        load_more,
        loading_orders,
        no_orders,
        title,
        your_orders,
      },
      prismicCartPage: { keep_shopping },
    },
  } = usePrismic()

  useEffect(() => {
    if (isAuthLoading) return
    isAuthenticated ? handleGetPageDataAndOrders() : navigate('/')
  }, [isAuthLoading, isAuthenticated])

  const isLastPage = ordersPageInfo?.current_page === 1

  if (isLoadingOrders || isAuthLoading)
    return (
      <Loading
        loading={isLoadingOrders || isAuthLoading}
        message={loading_orders[0].text}
      />
    )

  return (
    <>
      <Seo title={title[0].text} />
      <Wrapper>
        <PageHeader exitRoute="/">{your_orders[0].text}</PageHeader>
        {!ordersData?.length ? (
          <Container margin="4em 0 0 0" align="center" justify="center">
            <h1>{no_orders[0].text}</h1>

            <OrderButton data-qa="orderButton" as={Link} to="/products">
              {keep_shopping[0].text}
            </OrderButton>
          </Container>
        ) : (
          <Container>
            {!isLoadingOrders
              ? ordersData.map((order, number) => (
                  <OrderCard key={number} {...order} />
                ))
              : null}
            {isLastPage ? (
              <Container margin="4em 0" align="center" justify="center">
                <h1>{end_of_orders[0].text}</h1>
              </Container>
            ) : (
              <LoadMoreButton
                style={{ alignSelf: 'center' }}
                onClick={handleGetNextPageOrders}
                loading={isLoadingMoreOrders}
                data-qa="loadMoreButton"
              >
                {load_more[0].text}
              </LoadMoreButton>
            )}
          </Container>
        )}
      </Wrapper>
    </>
  )
}

export default OrderHistoryPage
